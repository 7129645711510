<template>
  <b-card no-body>
    <b-tabs card>
      <b-tab title="Általános szerződés" active>
        <simple-content />
      </b-tab>
      <b-tab title="Munkaszerződés">
        <b-card-text>Tab contents 2</b-card-text>
      </b-tab>
      <b-tab title="Szerződés 3" disabled>
        <b-card-text>Tab contents 2</b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>

import SimpleContent from '@/app/Projects/Generator/SimpleContract.vue'

import {
  BCard,
  BTabs,
  BTab,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    SimpleContent,
    BCardText,
  },
  setup() {
  },
}

</script>
