<template>
  <section class="invoice-preview-wrapper">

    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0 pt-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary invoice-logo">
                    Monolit
                  </h3>
                </div>
                <p class="card-text mb-25">
                  Iroda, Deberecen Kaszás utca 20
                </p>
                <p class="card-text mb-0">
                  +36 30 29 666 09
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Minta Szerződés
                  <span class="invoice-number">#{{ invoiceData.id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Kiállítás:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.issuedDate }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Teljesítés:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.dueDate }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="invoiceData.client"
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Címzett
                </h6>
                <h6 class="mb-25">
                  {{ invoiceData.client.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoiceData.client.company }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceData.client.address }}, {{ invoiceData.client.country }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceData.client.contact }}
                </p>
                <p class="card-text mb-0">
                  {{ invoiceData.client.companyEmail }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoiceDescription"
            :fields="['Tételek', 'Ára', 'Óraszám', 'Összesen']"
          >
            <template #cell(Tételek)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.taskTitle }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.taskDescription }}
              </b-card-text>
            </template>
            <template #cell(Ára)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.rate }} Ft
              </b-card-text>
            </template>
            <template #cell(Óraszám)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.hours }} óra
              </b-card-text>
            </template>
            <template #cell(Összesen)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.total }} Ft
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Értékesítő:</span>
                  <span class="ml-75">Szakállas Róbert</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Részösszeg:
                    </p>
                    <p class="invoice-total-amount">
                      420 000 Ft
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Adó:
                    </p>
                    <p class="invoice-total-amount">
                      0%
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Összesen:
                    </p>
                    <p class="invoice-total-amount">
                      420 000 Ft
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Letöltés PDF-ként
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Mentés és letöltés PDF-ként
          </b-button>

          <!-- Button: Print -->
          <p class="pt-2">
            <b>Címzett adatai</b>
          </p>
          <b-form-group
            label="Címzett neve"
            label-for="v-username"
          >
            <b-form-input
              id="v-username"
              placeholder="Kiss Pista"
            />
          </b-form-group>
          (Egyéb adatok amik kellenek itt megadhatóak)
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})

    invoiceData.value = {
      id: 2,
      issuedDate: '2022-01-01',
      client: {
        address: 'Budapest, Békásmegyer utca',
        company: 'DebSzoftver',
        companyEmail: 'szakallas.robert@debszoftver.hu',
        country: 'Magyarország',
        contact: '+36 30 29 666 09',
        name: 'Kiss Pista',
      },
      service: 'Software Development',
      total: 3428,
      dueDate: '2022-01-01',
    }

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        taskTitle: 'Tétle 1',
        taskDescription: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        rate: '10 000',
        hours: '30',
        total: '300 000',
      },
      {
        taskTitle: 'Tétel 2',
        taskDescription: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        rate: '6000',
        hours: '20',
        total: '120 000',
      },
    ]

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
